import request from '@/utils/request'

// 查询会员充值套餐列表
export function listMenu(query) {
  return request({
    url: '/user/vipMenu/list',
    method: 'get',
    params: query
  })
}

// 查询会员充值套餐分页
export function pageMenu(query) {
  return request({
    url: '/user/vipMenu/page',
    method: 'get',
    params: query
  })
}

// 查询会员充值套餐详细
export function getMenu(data) {
  return request({
    url: '/user/vipMenu/detail',
    method: 'get',
    params: data
  })
}

// 新增会员充值套餐
export function addMenu(data) {
  return request({
    url: '/user/vipMenu/add',
    method: 'post',
    data: data
  })
}

// 修改会员充值套餐
export function updateMenu(data) {
  return request({
    url: '/user/vipMenu/edit',
    method: 'post',
    data: data
  })
}

// 删除会员充值套餐
export function delMenu(data) {
  return request({
    url: '/user/vipMenu/delete',
    method: 'post',
    data: data
  })
}
